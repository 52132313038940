import { useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Container,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Swiper, SwiperSlide } from 'components/Shared/Swiper';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import MediaPressData from 'mocks/mediapress';

import MediaPressContent from './MediaPressContent';

MediaPress.propTypes = {
  showHeadline: PropTypes.bool,
};

MediaPress.defaultProps = {
  showHeadline: false,
};

export default function MediaPress({ showHeadline }) {
  const swiperRef = useRef(null);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleNext = () => {
    if (swiperRef.current) {
      swiperRef.current.swiper.slideNext();
    }
  };

  return (
    <Box id="mediapress" py={3} textAlign="center" minHeight={13}>
      <Container maxWidth="lg" sx={{ px: { xl: 27, lg: 8 } }}>
        <Grid container alignItems="center" spacing={{ xs: 1, md: 0 }}>
          {showHeadline && (
            <Grid item xs={12}>
              <Box mb={3}>
                <Typography variant="body2" component="h6">
                  Fluencypass na mídia
                </Typography>
              </Box>
            </Grid>
          )}

          {isMobile ? (
            <>
              <Grid item xs={11}>
                <Swiper
                  navigation={{ nextEl: '.next-button' }}
                  onSwiper={(swiper) => {
                    swiperRef.current = swiper;
                  }}
                  isPrimaryColor={false}
                  alternativeColor="#FFFFFF"
                  spaceBetween={24}
                  slidesPerView={3}
                >
                  {MediaPressData.map((media, index) => (
                    <SwiperSlide key={`media-${index}`}>
                      <MediaPressContent media={media} />
                    </SwiperSlide>
                  ))}
                </Swiper>
              </Grid>
              <Grid className="next-button" onClick={handleNext} item xs={1}>
                <ArrowForwardIosIcon fontSize="large" />
              </Grid>
            </>
          ) : (
            MediaPressData.map((media) => (
              <Grid item xs={6} sm={3} key={`${media.name}-key`}>
                <MediaPressContent media={media} />
              </Grid>
            ))
          )}
        </Grid>
      </Container>
    </Box>
  );
}
