const mediapress = [
  {
    name: 'Exame',
    href: 'https://exame.com/pme/ele-queria-intercambios-mais-baratos-e-simples-e-a-ideia-virou-negocio/',
    imageURL: 'Others/Medias/logo_exame_30px_tb9g18.png',
    idealWidth: { xs: '80px', sm: '120px', md: '100px' },
    idealHeight: { xs: '14px', sm: '20px', md: '16px' },
    sizes: '(max-width: 768px) 100vw, (max-width: 1200px) 50vw',
    mt: '7px',
  },
  {
    name: 'Folha de São Paulo',
    href: 'https://www1.folha.uol.com.br/mercado/2018/01/1947270-veja-como-se-planejar-para-tirar-um-sabatico.shtml',
    imageURL: 'Others/Medias/logo_folha_sao_paulo_30px_or7iyi.png',
    idealWidth: { xs: '120px', sm: '154px', md: '144px' },
    idealHeight: { xs: '24px', sm: '32px', md: '30px' },
    sizes: '(max-width: 768px) 33vw, (max-width: 1200px) 50vw',
    mt: '4px',
  },
  {
    name: 'Band News',
    href: 'https://youtu.be/zDzfSb1Zv3o',
    imageURL: 'Others/Medias/logo_band_news_30px_loqvkr.png',
    idealWidth: { xs: '60px', sm: '70px' },
    idealHeight: { xs: '24px', sm: '30px', md: '30px' },
    sizes: '(max-width: 768px) 33vw, (max-width: 1200px) 50vw',
  },
  {
    name: 'RA1000',
    href: '',
    imageURL: 'Others/Icons/Payments/certificado_RA_Colorido_jfywxd',
    idealWidth: { xs: '70px', sm: '88px', md: '106px' },
    idealHeight: { xs: '34px', sm: '42px', md: '53px' },
    sizes: '(max-width: 768px) 100vw, (max-width: 1200px) 50vw',
  },
];

export default mediapress;
