import Image from 'next/image';
import { Box } from '@mui/material';

import CustomLink from 'components/Buttons/CustomLinkComponent';

import cloudinaryLoader from 'utils/loaderUtils';

export default function MediaPress({ media }) {
  return (
    <Box
      mt={media.mt}
      alignItems="center"
      display="flex"
      justifyContent="center"
      mx="auto"
    >
      {media.href ? (
        <CustomLink
          href={media.href}
          rel="noopener noreferrer nofollow"
          target="_blank"
          position="relative"
          height={media.idealHeight}
          width={media.idealWidth}
        >
          <Image
            alt={`${media.name} logo`}
            fill
            loader={cloudinaryLoader}
            priority
            quality={100}
            sizes={media.sizes}
            src={media.imageURL}
          />
        </CustomLink>
      ) : (
        <Box
          position="relative"
          height={media.idealHeight}
          width={media.idealWidth}
        >
          <Image
            alt={`${media.name} logo`}
            fill
            loader={cloudinaryLoader}
            priority
            quality={100}
            sizes={media.sizes}
            src={media.imageURL}
          />
        </Box>
      )}
    </Box>
  );
}
